<template>
  <v-sheet
    color="grey lighten-1"
    class="d-flex align-center justify-center"
    height="100%"
  >
    <div class="mx-2">
      <v-skeleton-loader
          v-if="overlayLoading"
          class="mx-auto"
          width="400"
          type="card"
        ></v-skeleton-loader>
      <v-card class="elevation-10 pa-5" v-else>
        <v-card-title>
          Confirmação de Telefone
        </v-card-title>
        <v-card-text>
          
          <v-alert v-model="alert.showing"  :type="alert.type" prominent>
            {{ alert.text }}
          </v-alert>
          <v-alert v-model="successAlert" type="success">
            Telefone Validado
          </v-alert>
          
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text color="black" large>
            Abrir APP
            <v-icon class="mx-1">mdi-open-in-new</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay :value="overlayLoading">
        <v-progress-circular
          :size="70"
          :width="7"
          color="success"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: "ConfirmPhone",
  data: () => ({
    successAlert: false,
    overlayLoading: false,
    alert: {
      type: "error",
      text: "",
      showing: false,
    },
  }),
  mounted() {
    this.confirmEmail()
  },
  methods: {
    confirmEmail(){
      this.overlayLoading = true;
      this.alert.showing = false;
      this.$axios.put(`/confirm-phone/${this.$route.params.code}`)
      .then((response) => {
        this.successAlert = true;
        
      })
      .catch((error) => {
        let msg = "Erro Inesperado"
        let type = "error"
        if (error.response) {
          switch (error.response.status){
            case 401: msg = "Código de Recuperação Expirado"; break;
            case 404: msg = "Código de Recuperação Incorreto"; break;
            case 410: msg = "Telefone já Validado" ; type = "warning"; break;         
          }
        }
        this.alert.text = msg
        this.alert.type = type
        this.alert.showing = true
      })
      .finally(() => {
        this.overlayLoading = false
      })
    }
  }
}
</script>
